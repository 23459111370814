import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassAssignmentCreateUpdate = lazy(() => import("../../components/ClassAssignment/ClassAssignmentCreateUpdate"),);

const ClassAssignmentCreateUpdatePage = () => {
  return (
      <MasterLayout>
          <Suspense fallback={<LazyLoader />}>
            <ClassAssignmentCreateUpdate />
          </Suspense>
      </MasterLayout>
  );
};
export default ClassAssignmentCreateUpdatePage;
