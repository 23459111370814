import { createSlice } from "@reduxjs/toolkit";
const CourseSlice = createSlice({
  name: "Course",
  initialState: {
    CourseLists: [],
    CourseDropDown: [],
    TotalCourse: 0,
    FormValue: {
      imgX: "",
      imgXS: "",
      title: "",
      shortDes: "",
      keyTag: "",
      price: "",
      discount:'',
      discountPrice: "",
      instructorName: "",
      instructorImg: "",
      instructorDesignation: "",
      enrolledNumber: "",
      type: "",
    },
  },
  reducers: {
    SetCourseLists(state, action) {
      state.CourseLists = action.payload;
    },
    SetTotalCourse(state, action) {
      state.TotalCourse = action.payload;
    },
    SetCourseDropDown(state, action) {
      state.CourseDropDown = action.payload;
    },
    SetCourseDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        imgX: "",
        imgXS: "",
        title: "",
        shortDes: "",
        keyTag: "",
        price: "",
        discount: false,
        discountPrice: "",
        instructorName: "",
        instructorImg: "",
        instructorDesignation: "",
        enrolledNumber: "",
        type: "",
      };
    },
  },
});

export const {SetCourseLists, SetTotalCourse, SetCourseDropDown,SetCourseDetails,SetFormValueOnChange,ResetFormValue} = CourseSlice.actions;
export default CourseSlice.reducer;
