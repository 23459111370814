//external import
import { createSlice } from "@reduxjs/toolkit";

const ReviewSlice = createSlice({
  name: "Review",
  initialState: {
    ReviewLists: [],
    ReviewDropDown: [],
    TotalReview: 0,
    FormValue: {
      rectangleImg: "",
      circleImg: "",
      name: "",
      designation: "",
      comments: "",
    },
  },
  reducers: {
    SetReviewLists(state, action) {
      state.ReviewLists = action.payload;
    },
    SetTotalReview(state, action) {
      state.TotalReview = action.payload;
    },
    SetReviewDropDown(state, action) {
      state.ReviewDropDown = action.payload;
    },
    SetReviewDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        rectangleImg: "",
        circleImg: "",
        name: "",
        designation: "",
        comments: "",
      };
    },
  },
});

export const {
  SetReviewLists,
  SetTotalReview,
  SetReviewDropDown,
  SetReviewDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = ReviewSlice.actions;
export default ReviewSlice.reducer;
