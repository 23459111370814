//external import
import { createSlice } from "@reduxjs/toolkit";

const TechnologySlice = createSlice({
  name: "Technology",
  initialState: {
    TechnologyLists: [],
    TechnologyDropDown: [],
    TotalTechnology: 0,
    FormValue: {
      name: "",
      img: "",
    },
  },
  reducers: {
    SetTechnologyLists(state, action) {
      state.TechnologyLists = action.payload;
    },
    SetTotalTechnology(state, action) {
      state.TotalTechnology = action.payload;
    },
    SetTechnologyDropDown(state, action) {
      state.TechnologyDropDown = action.payload;
    },
    SetTechnologyDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        name: "",
        img: "",
      };
    },
  },
});

export const {
  SetTechnologyLists,
  SetTotalTechnology,
  SetTechnologyDropDown,
  SetTechnologyDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = TechnologySlice.actions;
export default TechnologySlice.reducer;
