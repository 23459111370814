import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const WhyusList = lazy(() => import("../../components/Whyus/WhyusList"));

const WhyusListPage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <WhyusList />
            </Suspense>
        </MasterLayout>
  );
};
export default WhyusListPage;
