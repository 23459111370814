//external import
import { createSlice } from "@reduxjs/toolkit";

const ClassAssignmentSlice = createSlice({
  name: "ClassAssignment",
  initialState: {
    ClassAssignmentLists: [],
    TotalClassAssignment: 0,
    FormValue: {
      courseID: "",
      title: "",
      link: "",
    },
  },
  reducers: {
    SetClassAssignmentLists(state, action) {
      state.ClassAssignmentLists = action.payload;
    },
    SetTotalClassAssignment(state, action) {
      state.TotalClassAssignment = action.payload;
    },
    SetClassAssignmentDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        courseID: "",
        title: "",
        link: "",
      };
    },
  },
});

export const {
  SetClassAssignmentLists,
  SetTotalClassAssignment,
  SetClassAssignmentDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = ClassAssignmentSlice.actions;
export default ClassAssignmentSlice.reducer;
