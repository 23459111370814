import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {AiOutlineDelete, AiOutlineEdit, AiOutlineLink} from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ClassAssignmentRequest from "../../APIRequest/ClassAssignmentRequest";
import {DeleteAlert} from "../../helper/DeleteAlert";

const ClassAssignmentList = () => {

  let [searchKeyword,setSearchKeyword]=useState("0");
  let [perPage,setPerPage]=useState(50);

  useEffect(()=>{
    (async () => {
      await ClassAssignmentRequest.ClassAssignmentList(1, perPage, searchKeyword);
    })();
  },[])

  let DataList=useSelector((state)=>(state.ClassAssignment.ClassAssignmentLists));
  let Total=useSelector((state)=>(state.ClassAssignment.TotalClassAssignment))

  const handlePageClick = async (event) => {
    await ClassAssignmentRequest.ClassAssignmentList(event.selected + 1, perPage, searchKeyword)
  };

  const searchData=async () => {
    await ClassAssignmentRequest.ClassAssignmentList(1, perPage, searchKeyword)
  }

  const perPageOnChange=async (e) => {
    setPerPage(parseInt(e.target.value))
    await ClassAssignmentRequest.ClassAssignmentList(1, e.target.value, searchKeyword)
  }

  const searchKeywordOnChange=async (e) => {
    setSearchKeyword(e.target.value)
    if ((e.target.value).length === 0) {
      setSearchKeyword("0")
      await ClassAssignmentRequest.ClassAssignmentList(1, perPage, "0");
    }
  }

  const TextSearch = (e) => {
    const rows = document.querySelectorAll('tbody tr')
    rows.forEach(row => {
      row.style.display = (row.innerText.includes(e.target.value)) ? '' : 'none'
    })
  }

  const DeleteItem = async (id) => {
    let Result = await DeleteAlert();
    if(Result.isConfirmed){
      let DeleteResult= await ClassAssignmentRequest.ClassAssignmentDelete(id)
      if(DeleteResult){
        await ClassAssignmentRequest.ClassAssignmentList(1,perPage,searchKeyword);
      }
    }
  }


  return (
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-4">
                      <h5>Assignment List ({Total})</h5>
                    </div>
                    <div className="col-2">
                      <input onKeyUp={TextSearch} placeholder="Text Filter" className="form-control form-control-sm"/>
                    </div>
                    <div className="col-2">
                      <select onChange={perPageOnChange} className="form-control mx-2 form-select-sm form-select form-control-sm" >
                        <option value="50">50 Per Page</option>
                        <option value="100">100 Per Page</option>
                        <option value="200">200 Per Page</option>
                        <option value="400">400 Per Page</option>
                        <option value="600">600 Per Page</option>
                      </select>
                    </div>
                    <div className="col-4">
                      <div className="input-group mb-3">
                        <input onChange={searchKeywordOnChange} type="text" className="form-control form-control-sm" placeholder="Search.." aria-label="Recipient's username" aria-describedby="button-addon2"/>
                        <button onClick={searchData} className="btn  btn-success btn-sm mb-0" type="button">Search</button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive table-section">
                        <table className="table ">
                          <thead className="sticky-top bg-white">
                          <tr>
                            <td className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Assignment</td>
                            <td className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Link</td>
                            <td className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Action</td>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            DataList.map((item,i)=>{
                              return(
                                  <tr>
                                    <td>
                                      <div className="d-flex px-2 py-1">
                                        <div className="d-flex flex-column justify-content-center">
                                          <p className="text-xs font-weight-bold  mb-0">{item['title']}</p>
                                          <h6 className="mb-0 text-secondary text-xxs">{item['course'][0]['title']}</h6>
                                        </div>
                                      </div>
                                    </td>

                                    <td>
                                      <a target="_blank" href= {item['link']} className="btn btn-outline-light text-success p-2 mb-0 btn-sm ms-2"><AiOutlineLink/></a>
                                    </td>

                                    <td>
                                      <button onClick={DeleteItem.bind(this,item["_id"])} className="btn btn-outline-light text-danger p-2 mb-0 btn-sm ms-2"><AiOutlineDelete size={15} /></button>
                                      <Link to={`/class-assignment-create-update?id=${item._id}`} className="btn btn-outline-light text-success p-2 mb-0 btn-sm ms-2"><AiOutlineEdit size={15} /></Link>
                                    </td>

                                  </tr>
                              )
                            })
                          }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-12 mt-5">
                      <nav aria-label="Page navigation example">
                        <ReactPaginate
                            initialPage={0}
                            previousLabel="<"
                            nextLabel=">"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            pageCount={Total/perPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName="pagination"
                            activeClassName="active"
                        />
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ClassAssignmentList;
