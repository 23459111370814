//Internal Import
import ToastMessage from "../helper/ToastMessage";
import {
  SetTotalClassAssignment,
  SetClassAssignmentLists,
  SetClassAssignmentDetails,
} from "../redux/slices/ClassAssignmentSlice";
import { ResetFormValue } from "../redux/slices/CourseSlice";
import store from "../redux/store/store";
import RestClient from "./RestClient";

class ClassAssignmentRequest {
  static async ClassAssignmentCreate(postBody) {
    const { data } = await RestClient.postRequest(
      "/ClassAssignmentCreate",
      postBody,
    );

    if (data.status === "success") {
      store.dispatch(ResetFormValue());
      ToastMessage.successMessage("Class Assignment Create Successful");
      return true;
    }
  }

  static async ClassAssignmentList(pageNumber, perPage, searchKey) {
    const { data } = await RestClient.getRequest(
      `ClassAssignmentList/${pageNumber}/${perPage}/${searchKey}`,
    );

    if (data.status === "success") {
      store.dispatch(ResetFormValue());
      const total = data?.data[0]?.Total[0]?.count;
      store.dispatch(SetClassAssignmentLists(data?.data?.[0]?.Data));
      store.dispatch(SetTotalClassAssignment(total || 0));
    }
  }

  static async ClassAssignmentDetails(id, postBody) {
    const { data } = await RestClient.getRequest(`/ClassAssignmentDetails/${id}`);

    if (data.status === "success") {
      store.dispatch(SetClassAssignmentDetails(data?.data?.[0]));
      return true;
    }
  }

  static async ClassAssignmentUpdate(id, postBody) {
    const { data } = await RestClient.updateRequest(
      `/ClassAssignmentUpdate/${id}`,
      postBody,
    );

    if (data.status === "success") {
      store.dispatch(ResetFormValue());
      ToastMessage.successMessage("Class Assignment Update Successful");
      return true;
    }
  }

  static async ClassAssignmentDelete(id) {
    const { data } = await RestClient.deleteRequest(
      `/ClassAssignmentDelete/${id}`,
    );

    if (data.status === "success") {
      ToastMessage.successMessage("Class Assignment Delete Successful");
      return true;
    }
  }
}

export default ClassAssignmentRequest;
