//External import
import { configureStore } from "@reduxjs/toolkit";

//Internal Import
import LoaderSlice from "../slices/LoaderSlice";
import AuthSlice from "../slices/AuthSlice";
import ProfileSlice from "../slices/ProfileSlice";
import UserSlice from "../slices/UserSlice";
import ContactSlice from "../slices/ContactSlice";
import SubscriberSlice from "../slices/SubscriberSlice";
import TechnologySlice from "../slices/TechnologySlice";
import ReviewSlice from "../slices/ReviewSlice";
import CourseSlice from "../slices/CourseSlice";
import CourseDetailsSlice from "../slices/CourseDetailsSlice";
import HomeheroSlice from "../slices/HomeheroSlice";
import RecommendationSlice from "../slices/RecommendationSlice";
import LegalSlice from "../slices/LegalSlice";
import WhyusSlice from "../slices/WhyusSlice";
import FooterInfoSlice from "../slices/FooterInfoSlice";
import EnrollSlice from "../slices/EnrollSlice";
import ClassCategorySlice from "../slices/ClassCategorySlice";
import ClassListSlice from "../slices/ClassListSlice";
import ClassResourceSlice from "../slices/ClassResourceSlice";
import ClassStudyPlanSlice from "../slices/ClassStudyPlanSlice";
import ClassAssignmentSlice from "../slices/ClassAssignmentSlice";

const store = configureStore({
  reducer: {
    Loader: LoaderSlice,
    Auth: AuthSlice,
    Profile: ProfileSlice,
    User: UserSlice,
    Contact: ContactSlice,
    Subscriber: SubscriberSlice,
    Technology: TechnologySlice,
    Review: ReviewSlice,
    Course: CourseSlice,
    CourseDetails: CourseDetailsSlice,
    Homehero: HomeheroSlice,
    Recommendation: RecommendationSlice,
    Legal: LegalSlice,
    Whyus: WhyusSlice,
    FooterInfo: FooterInfoSlice,
    Enroll: EnrollSlice,
    ClassCategory: ClassCategorySlice,
    ClassList: ClassListSlice,
    ClassResource: ClassResourceSlice,
    ClassStudyPlan: ClassStudyPlanSlice,
    ClassAssignment: ClassAssignmentSlice,
  },
});

export default store;
