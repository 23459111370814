import { useSelector } from "react-redux";
const FullScreenLoader = () => {
  const loader = useSelector((state) => state.Loader.IsLoading);
  return (
    <div className={loader ? "LoadingOverlay" : "d-none"}>
        <div className="Line-Progress">
            <div className="indeterminate"/>
        </div>
    </div>
  );
};

export default FullScreenLoader;
