import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassCategoryCreateUpdate = lazy(() => import("../../components/ClassCategory/ClassCategoryCreateUpdate"));

const ClassCategoryCreateUpdatePage = () => {
  return (
   <MasterLayout>
       <Suspense fallback={<LazyLoader />}>
           <ClassCategoryCreateUpdate />
       </Suspense>
   </MasterLayout>
  );
};

export default ClassCategoryCreateUpdatePage;
