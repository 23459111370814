import React, {Fragment} from "react";
import { Container, Navbar } from "react-bootstrap";
import {AiOutlineLogout, AiOutlineMenuUnfold, AiOutlineUser,} from "react-icons/ai";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { SetLogout } from "../../redux/slices/AuthSlice";
import store from "../../redux/store/store";
import ToastMessage from "../../helper/ToastMessage";
function Navigation({ openMenu, setOpenMenu }) {

  const { ProfileDetails } = useSelector((state) => state.Profile);
  const logoutUser = () => {
    store.dispatch(SetLogout());
    ToastMessage.successMessage("User Logout Successful");
  };
  return (
    <Fragment>
      <Navbar className={openMenu ? "fixed-top px-0 shadow-sm top-nav-open ":" fixed-top px-0 shadow-sm top-nav-close"}>
        <Container fluid={true}>
          <Navbar.Brand>
            <button className="icon-nav m-0 h4 btn text-white btn-link" onClick={() => setOpenMenu(!openMenu)}><AiOutlineMenuUnfold /></button>
          </Navbar.Brand>
          <div className="float-right h-auto d-flex align-items-center">
            <div className="user-dropdown">
              <img className="icon-nav-img icon-nav" src={ProfileDetails?.photo} alt=""/>
              <div className="user-dropdown-content ">
                <div className="mt-4 text-center">
                  <img className="icon-nav-img" src={ProfileDetails?.photo} alt=""/>
                  <h6>{ProfileDetails?.firstName}</h6>
                  <hr className="user-dropdown-divider  p-0"/>
                </div>
                <NavLink to="/Profile" className="side-bar-item">
                  <AiOutlineUser className="side-bar-item-icon" />
                  <span className="side-bar-item-caption">Profile</span>
                </NavLink>
                <a onClick={logoutUser}  className="side-bar-item">
                  <AiOutlineLogout className="side-bar-item-icon" />
                  <span className="side-bar-item-caption">Logout</span>
                </a>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
}
export default Navigation;
