import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const HomeheroCreateUpdate = lazy(() => import("../../components/Homehero/HomeheroCreateUpdate"));
const HomeheroCreateUpdatePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <HomeheroCreateUpdate />
            </Suspense>
        </MasterLayout>
  );
};
export default HomeheroCreateUpdatePage;
