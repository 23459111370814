import React, {Fragment, useState} from "react";
import Navigation from "./Navigation";
import SideBar from "./SideBar";
const MasterLayout = ({ children, title }) => {
  const [openMenu, setOpenMenu] = useState(true);
  return (
    <Fragment>
      <Navigation openMenu={openMenu} setOpenMenu={setOpenMenu} title={title} />
      <SideBar openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <div className={openMenu ? "content" : "content-expand"}>{children}</div>
    </Fragment>
  );
};
export default MasterLayout;
