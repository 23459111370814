//external import
import { createSlice } from "@reduxjs/toolkit";

const HomeheroSlice = createSlice({
  name: "Homehero",
  initialState: {
    HomeheroLists: [],
    TotalHomehero: 0,
    FormValue: {
      title: "",
      name: "",
      des: "",
      totalEnrolled: "",
      totalCourses: "",
      totalFollowers: "",
      img: false,
    },
  },
  reducers: {
    SetHomeheroLists(state, action) {
      state.HomeheroLists = action.payload;
    },
    SetTotalHomehero(state, action) {
      state.TotalHomehero = action.payload;
    },
    SetHomeheroDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        title: "",
        name: "",
        des: "",
        totalEnrolled: "",
        totalCourses: "",
        totalFollowers: "",
        img: false,
      };
    },
  },
});

export const {
  SetHomeheroLists,
  SetTotalHomehero,
  SetHomeheroDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = HomeheroSlice.actions;
export default HomeheroSlice.reducer;
