import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassResourceCreateUpdate = lazy(() => import("../../components/ClassResource/ClassResourceCreateUpdate"),);

const ClassResourceCreateUpdatePage = () => {
  return (
  <MasterLayout>
      <Suspense fallback={<LazyLoader />}>
          <ClassResourceCreateUpdate />
      </Suspense>
  </MasterLayout>
  );
};

export default ClassResourceCreateUpdatePage;
