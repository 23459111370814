//external import
import { createSlice } from "@reduxjs/toolkit";

const CourseDetailsSlice = createSlice({
  name: "CourseDetails",
  initialState: {
    CourseDetailsLists: [],
    TotalCourseDetails: 0,
    FormValue: {
      courseID: "",
      level: "",
      lectures: "",
      duration: "",
      language: "",
      paymentImg: "",
      coursePlanVideoLink: "",
      freeClassesVideoLink: "",
      courseFeaturesVideoLink: "",
      howToEnrollVideoLink: "",
      overview: "",
      curriculum: "",
      InstructorAbout: "",
      paymentInstruction: "",
    },
  },
  reducers: {
    SetCourseDetailsLists(state, action) {
      state.CourseDetailsLists = action.payload;
    },
    SetTotalCourseDetails(state, action) {
      state.TotalCourseDetails = action.payload;
    },
    SetCourseDetailsDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        courseID: "",
        level: "",
        lectures: "",
        duration: "",
        language: "",
        paymentImg: "",
        coursePlanVideoLink: "",
        freeClassesVideoLink: "",
        courseFeaturesVideoLink: "",
        howToEnrollVideoLink: "",
        overview: "",
        curriculum: "",
        InstructorAbout: "",
        paymentInstruction: "",
      };
    },
  },
});

export const {
  SetCourseDetailsLists,
  SetTotalCourseDetails,
  SetCourseDetailsDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = CourseDetailsSlice.actions;
export default CourseDetailsSlice.reducer;
