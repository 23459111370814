import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassListCreateUpdate = lazy(() => import("../../components/ClassList/ClassListCreateUpdate"),);
const ClassListCreateUpdatePage = () => {
  return (
      <MasterLayout>
          <Suspense fallback={<LazyLoader />}>
              <ClassListCreateUpdate />
          </Suspense>
      </MasterLayout>
  );
};
export default ClassListCreateUpdatePage;
