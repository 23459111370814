import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const HomeheroList = lazy(() => import("../../components/Homehero/HomeheroList"),);
const HomeheroListPage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <HomeheroList />
            </Suspense>
        </MasterLayout>
  );
};
export default HomeheroListPage;
