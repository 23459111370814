import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const TechnologyCreateUpdate = lazy(() => import("../../components/Technology/TechnologyCreateUpdate"));

const TechnologyCreateUpdatePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <TechnologyCreateUpdate />
            </Suspense>
        </MasterLayout>
  );
};
export default TechnologyCreateUpdatePage;
