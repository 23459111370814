//external import
import { createSlice } from "@reduxjs/toolkit";

const ClassResourceSlice = createSlice({
  name: "ClassResource",
  initialState: {
    ClassResourceLists: [],
    TotalClassResource: 0,
    FormValue: {
      courseID: "",
      title: "",
      link: "",
    },
  },
  reducers: {
    SetClassResourceLists(state, action) {
      state.ClassResourceLists = action.payload;
    },
    SetTotalClassResource(state, action) {
      state.TotalClassResource = action.payload;
    },
    SetClassResourceDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      debugger;
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        courseID: "",
        title: "",
        link: "",
      };
    },
  },
});

export const {
  SetClassResourceLists,
  SetTotalClassResource,
  SetClassResourceDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = ClassResourceSlice.actions;
export default ClassResourceSlice.reducer;
