import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassListListPage = lazy(() => import("../../components/ClassList/ClassListList"));

const ClassListListPagePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <ClassListListPage />
            </Suspense>
        </MasterLayout>
  );
};

export default ClassListListPagePage;
