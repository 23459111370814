import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const AdminProfile = lazy(() => import("../../components/Admin/AdminProfile"));
const AdminProfilePage = () => {
  return (
      <MasterLayout>
        <Suspense fallback={<LazyLoader/>}>
            <AdminProfile/>
        </Suspense>
      </MasterLayout>
  );
};
export default AdminProfilePage;
