import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassResourceList = lazy(() => import("../../components/ClassResource/ClassResourceList"));
const ClassResourceListPagePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <ClassResourceList />
            </Suspense>
        </MasterLayout>
  );
};
export default ClassResourceListPagePage;
