//External Lib Import
import { createSlice } from "@reduxjs/toolkit";
import SessionHelper from "../../helper/SessionHelper";

const ProfileSlice = createSlice({
  name: "Profile",
  initialState: {
    ProfileDetails: SessionHelper.GetUserDetails() || undefined,
  },
  reducers: {
    SetProfileDetails(state, action) {
      SessionHelper.SetUserDetails(action.payload);
      state.ProfileDetails = SessionHelper.GetUserDetails() || undefined;
    },
    RemoveProfileDetails(state, action) {
      SessionHelper.RemoveUserDetails();
      state.ProfileDetails = SessionHelper.GetUserDetails() || undefined;
    },
  },
});

export const { SetProfileDetails, RemoveProfileDetails } = ProfileSlice.actions;
export default ProfileSlice.reducer;
