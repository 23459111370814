import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassStudyPlanCreateUpdate = lazy(() => import("../../components/ClassStudyPlan/ClassStudyPlanCreateUpdate"));

const ClassAssignmentCreateUpdatePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader/>}>
                <ClassStudyPlanCreateUpdate/>
            </Suspense>
        </MasterLayout>
  );
};
export default ClassAssignmentCreateUpdatePage;
