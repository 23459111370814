import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassAssignmentList = lazy(() => import("../../components/ClassAssignment/ClassAssignmentList"));
const ClassAssignmentListPage = () => {
  return (
      <MasterLayout>
          <Suspense fallback={<LazyLoader />}>
              <ClassAssignmentList/>
          </Suspense>
      </MasterLayout>
  );
};
export default ClassAssignmentListPage;
