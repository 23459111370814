import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const CourseCreateUpdate = lazy(() => import("../../components/CourseDetails/CourseDetailsCreateUpdate"));

const CourseDetailsCreateUpdatePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <CourseCreateUpdate />
            </Suspense>
        </MasterLayout>
  );
};
export default CourseDetailsCreateUpdatePage;
