//external import
import { createSlice } from "@reduxjs/toolkit";

const RecommendationSlice = createSlice({
  name: "Recommendation",
  initialState: {
    RecommendationLists: [],
    TotalRecommendation: 0,
    FormValue: {
      name: "",
      link: "",
      des: "",
    },
  },
  reducers: {
    SetRecommendationLists(state, action) {
      state.RecommendationLists = action.payload;
    },
    SetTotalRecommendation(state, action) {
      state.TotalRecommendation = action.payload;
    },
    SetRecommendationDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        name: "",
        link: "",
        des: "",
      };
    },
  },
});

export const {
  SetRecommendationLists,
  SetTotalRecommendation,
  SetRecommendationDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = RecommendationSlice.actions;
export default RecommendationSlice.reducer;
