import { createSlice } from "@reduxjs/toolkit";
const EnrollSlice = createSlice({
  name: "Enroll",
  initialState: {
    EnrollLists: [],
    TotalEnroll: 0,
    FormValue: {
      courseID: "",
      mfsMethod: "",
      mfsMobileNo: "",
      mfsTrx: "",
      userEmail: ""
    },
  },
  reducers: {
    SetEnrollLists(state, action) {
      state.EnrollLists = action.payload;
    },
    SetTotalEnroll(state, action) {
      state.TotalEnroll = action.payload;
    },
    SetEnrollDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {courseID: "", mfsMethod: "", mfsMobileNo: "", mfsTrx: "", userEmail: ""};
    },
  },
});

export const {SetEnrollLists, SetTotalEnroll, SetEnrollDetails, SetFormValueOnChange, ResetFormValue} = EnrollSlice.actions;
export default EnrollSlice.reducer;
