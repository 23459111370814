import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const SubscriberList = lazy(() => import("../../components/Subscriber/SubscriberList"),);

const SubscriberListPage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <SubscriberList />
            </Suspense>
        </MasterLayout>
  );
};
export default SubscriberListPage;
