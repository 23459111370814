import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const WhyusCreateUpdate = lazy(() => import("../../components/Whyus/WhyusCreateUpdate"));

const WhyusCreateUpdatePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <WhyusCreateUpdate />
            </Suspense>
        </MasterLayout>
  );
};
export default WhyusCreateUpdatePage;
