import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const FooterInfoList = lazy(() => import("../../components/FooterInfo/FooterInfoList"));

const FooterInfoListPage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <FooterInfoList />
            </Suspense>
        </MasterLayout>
  );
};
export default FooterInfoListPage;
