
import React from "react";
import { Accordion } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import {BsCircle, BsEnvelopeOpen, BsFillXDiamondFill, BsPeople, BsShop, BsStack, BsStickiesFill, BsSubtract, BsSuitClubFill, BsTextIndentRight, BsUiChecksGrid, BsUiRadios,BsXDiamond} from "react-icons/bs";
import { RiDashboardLine } from "react-icons/ri";
import Logo from "../../assets/images/logo.svg";

function SideBar({ openMenu }) {
  const sidebarItems = [
    {
      title: "Dashboard",
      icon: <RiDashboardLine className="side-bar-item-icon" />,
      url: "/",
      subMenu: [],
    },
    {
      title: "Contact",
      icon: <BsEnvelopeOpen className="side-bar-item-icon" />,
      url: "/Contact",
      subMenu: [
        {
          title: "Contact List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/contact-list",
        },
      ],
    },
    {
      title: "User",
      icon: <BsPeople className="side-bar-item-icon" />,
      url: "/user",
      subMenu: [
        {
          title: "User List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/user-list",
        },
      ],
    },
    {
      title: "Enroll",
      icon: <BsTextIndentRight className="side-bar-item-icon" />,
      url: "/",
      subMenu: [
        {
          title: "Add Enroll",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/enroll-create-update",
        },
        {
          title: "Enroll List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/enroll-list",
        },
      ],
    },
    {
      title: "Technology",
      icon: <BsFillXDiamondFill className="side-bar-item-icon" />,
      url: "/Technology",
      subMenu: [
        {
          title: "Add New",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/technology-create-update",
        },
        {
          title: "Technology List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/technology-list",
        },
      ],
    },
    {
      title: "Subscriber",
      icon: <BsSubtract className="side-bar-item-icon" />,
      url: "/Subscriber",
      subMenu: [
        {
          title: "Subscriber List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/subscriber-list",
        },
      ],
    },
    {
      title: "Review",
      icon: <BsUiChecksGrid className="side-bar-item-icon" />,
      url: "/Review",
      subMenu: [
        {
          title: "New Review",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/review-create-update",
        },
        {
          title: "Review List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/review-list",
        },
      ],
    },
    {
      title: "Course",
      icon: <BsStack className="side-bar-item-icon" />,
      url: "/Course",
      subMenu: [
        {
          title: "New Course",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/course-create-update",
        },
        {
          title: "Course List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/course-list",
        },
      ],
    },
    {
      title: "Course Details",
      icon: <BsUiRadios className="side-bar-item-icon" />,
      url: "/CourseDetails",
      subMenu: [
        {
          title: "New Course Details",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/coursedetails-create-update",
        },
        {
          title: "Course Details List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/coursedetails-list",
        },
      ],
    },

    {
      title: "Homehero",
      icon: <BsShop className="side-bar-item-icon" />,
      url: "/Homehero",
      subMenu: [
        {
          title: "New Homehero",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/homehero-create-update",
        },
        {
          title: "Homehero List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/homehero-list",
        },
      ],
    },
    {
      title: "Recommendation",
      icon: <BsSuitClubFill className="side-bar-item-icon" />,
      url: "/Recommendation",
      subMenu: [
        {
          title: "New Recommendation",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/recommendation-create-update",
        },
        {
          title: "Recommendation List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/recommendation-list",
        },
      ],
    },
    {
      title: "Legal",
      icon: <BsPeople className="side-bar-item-icon" />,
      url: "/Legal",
      subMenu: [
        {
          title: "New Legal",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/legal-create-update",
        },
        {
          title: "Legal List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/legal-list",
        },
      ],
    },
    {
      title: "Why us",
      icon: <BsStickiesFill className="side-bar-item-icon" />,
      url: "/Whyus",
      subMenu: [
        {
          title: "New Why us",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/whyus-create-update",
        },
        {
          title: "Why us List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/whyus-list",
        },
      ],
    },
    {
      title: "Footer Info",
      icon: <BsXDiamond className="side-bar-item-icon" />,
      url: "/FooterInfo",
      subMenu: [
        {
          title: "New Footer Info",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/footerInfo-create-update",
        },
        {
          title: "Footer Info List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/footerInfo-list",
        },
      ],
    },
    {
      title: "Class Category",
      icon: <BsXDiamond className="side-bar-item-icon" />,
      url: "/class-category",
      subMenu: [
        {
          title: "New Category",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-category-create-update",
        },
        {
          title: "Category List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-category-list",
        },
      ],
    },
    {
      title: "Class List",
      icon: <BsXDiamond className="side-bar-item-icon" />,
      url: "/class-list",
      subMenu: [
        {
          title: "New Class",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-list-create-update",
        },
        {
          title: "Class List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-list",
        },
      ],
    },
    {
      title: "Class Resource",
      icon: <BsXDiamond className="side-bar-item-icon" />,
      url: "/class-resource",
      subMenu: [
        {
          title: "New Class Resource",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-resource-create-update",
        },
        {
          title: "Resource List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-resource-list",
        },
      ],
    },
    {
      title: "Class Study Plan",
      icon: <BsXDiamond className="side-bar-item-icon" />,
      url: "/class-studyPlan",
      subMenu: [
        {
          title: "New Study Plan",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-study-plan-create-update",
        },
        {
          title: "Plan List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-study-plan-list",
        },
      ],
    },
    {
      title: "Class Assignment",
      icon: <BsXDiamond className="side-bar-item-icon" />,
      url: "/class-assignment",
      subMenu: [
        {
          title: "New Assignment",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-assignment-create-update",
        },
        {
          title: "Assignment List",
          icon: <BsCircle size={16} className="side-bar-subitem-icon" />,
          url: "/class-assignment-list",
        },
      ],
    },
  ];

  const isSidebarAccordionActive = () => {
    let urlList = [];
    sidebarItems.map((item) =>
      urlList.push(
        item.subMenu.map((subItem) => {
          return subItem?.url;
        }),
      ),
    );

    return urlList.findIndex((items) =>
      items.includes(window.location.pathname),
    );
  };

  return (
    <div className={openMenu ? "side-nav-open" : "side-nav-close"}>
      <div className="side-nav-top text-center">
        <Link to="/" className="d-block my-3">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <Accordion defaultActiveKey={`${isSidebarAccordionActive()}`}>
        {sidebarItems.map((item, index) => {
          return item.subMenu.length !== 0 ? (
            <Accordion.Item key={index} eventKey={`${index}`} className="mt-2">
              <Accordion.Header>
                <div className="side-bar-item">
                  {item.icon}
                  <span className="side-bar-item-caption">{item.title}</span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {item.subMenu.map((subItem, index) => (
                  <NavLink
                    key={index}
                    className={(navData) =>
                      navData.isActive
                        ? "side-bar-subitem-active side-bar-subitem "
                        : "side-bar-subitem"
                    }
                    to={subItem?.url}
                  >
                    {subItem?.icon}
                    <span className="side-bar-subitem-caption">
                      {subItem?.title}
                    </span>
                  </NavLink>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          ) : (
            <NavLink
              key={index}
              className={(navData) =>
                navData.isActive
                  ? "side-bar-item-active side-bar-item mt-2"
                  : "side-bar-item mt-2"
              }
              to={"/dashboard"}
              end
            >
              {item.icon}
              <span className="side-bar-item-caption">{item.title}</span>
            </NavLink>
          );
        })}
      </Accordion>
    </div>
  );
}

export default SideBar;
