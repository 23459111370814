import { createSlice } from "@reduxjs/toolkit";
const ContactSlice = createSlice({
  name: "Contact",
  initialState: {
    List: [],
    ListTotal: 0
  },
  reducers: {
    SetContactList(state, action) {
      state.List = action.payload;
    },
    SetTotalContact(state, action) {
      state.ListTotal = action.payload;
    }
  },
});
export const { SetContactList, SetTotalContact } = ContactSlice.actions;export default ContactSlice.reducer;
