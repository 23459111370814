import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const EnrollCreateUpdate = lazy(() => import("../../components/Enroll/EnrollCreateUpdate"),);

const EnrollCreateUpdatePage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <EnrollCreateUpdate />
            </Suspense>
        </MasterLayout>
  );
};
export default EnrollCreateUpdatePage;
