import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ReviewList = lazy(() => import("../../components/Review/ReviewList"),);
const ReviewListPage = () => {
  return (
    <MasterLayout>
        <Suspense fallback={<LazyLoader />}>
            <ReviewList />
        </Suspense>
    </MasterLayout>
  );
};
export default ReviewListPage;
