//external import
import { createSlice } from "@reduxjs/toolkit";

const SubscriberSlice = createSlice({
  name: "Subscriber",
  initialState: {
    SubscriberLists: [],
    TotalSubscriber: 0,
  },
  reducers: {
    SetSubscriberLists(state, action) {
      state.SubscriberLists = action.payload;
    },
    SetTotalSubscriber(state, action) {
      state.TotalSubscriber = action.payload;
    },
  },
});

export const { SetSubscriberLists, SetTotalSubscriber } =
  SubscriberSlice.actions;
export default SubscriberSlice.reducer;
