//external import
import { createSlice } from "@reduxjs/toolkit";

const LegalSlice = createSlice({
  name: "Legal",
  initialState: {
    LegalLists: [],
    TotalLegal: 0,
    FormValue: {
      type: "",
      des: "",
    },
  },
  reducers: {
    SetLegalLists(state, action) {
      state.LegalLists = action.payload;
    },
    SetTotalLegal(state, action) {
      state.TotalLegal = action.payload;
    },
    SetLegalDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        type: "",
        des: "",
      };
    },
  },
});

export const {
  SetLegalLists,
  SetTotalLegal,
  SetLegalDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = LegalSlice.actions;
export default LegalSlice.reducer;
