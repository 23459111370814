import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
const SentOtp = lazy(() => import("../../components/AccountRecovery/SendOtp"));

const SendOptPage = () => {
  return (
      <Suspense fallback={<LazyLoader />}>
        <SentOtp />
      </Suspense>
  );
};

export default SendOptPage;
