//external import
import { createSlice } from "@reduxjs/toolkit";

const FooterInfoSlice = createSlice({
  name: "FooterInfo",
  initialState: {
    FooterInfoLists: [],
    TotalFooterInfo: 0,
    FormValue: {
      about: "",
      address: "",
      facebook: "",
      youtube: "",
      linkedin: "",
      rights: "",
      paymentLogo1: "",
      paymentLogo2: "",
      paymentLogo3: "",
      paymentLogo4: "",
      paymentLogo5: "",
    },
  },
  reducers: {
    SetFooterInfoLists(state, action) {
      state.FooterInfoLists = action.payload;
    },
    SetTotalFooterInfo(state, action) {
      state.TotalFooterInfo = action.payload;
    },
    SetFooterInfoDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        about: "",
        address: "",
        facebook: "",
        youtube: "",
        linkedin: "",
        rights: "",
        paymentLogo1: "",
        paymentLogo2: "",
        paymentLogo3: "",
        paymentLogo4: "",
        paymentLogo5: "",
      };
    },
  },
});

export const {
  SetFooterInfoLists,
  SetTotalFooterInfo,
  SetFooterInfoDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = FooterInfoSlice.actions;
export default FooterInfoSlice.reducer;
