import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const ClassStudyPlanList = lazy(() => import("../../components/ClassStudyPlan/ClassStudyPlanList"),);
const ClassStudyPlanListPage = () => {
  return (
      <MasterLayout>
          <Suspense fallback={<LazyLoader />}>
              <ClassStudyPlanList />
          </Suspense>
      </MasterLayout>
  );
};
export default ClassStudyPlanListPage;
