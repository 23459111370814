import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const RecommendationList = lazy(() => import("../../components/Recommendation/RecommendationList"),);
const RecommendationListPage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <RecommendationList />
            </Suspense>
        </MasterLayout>
  );
};
export default RecommendationListPage;
