//external import
import { createSlice } from "@reduxjs/toolkit";

const ClassStudyPlanSlice = createSlice({
  name: "ClassStudyPlan",
  initialState: {
    ClassStudyPlanLists: [],
    TotalClassStudyPlan: 0,
    FormValue: {
      courseID: "",
      title: "",
      des: "",
    },
  },
  reducers: {
    SetClassStudyPlanLists(state, action) {
      state.ClassStudyPlanLists = action.payload;
    },
    SetTotalClassStudyPlan(state, action) {
      state.TotalClassStudyPlan = action.payload;
    },
    SetClassStudyPlanDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        courseID: "",
        title: "",
        des: "",
      };
    },
  },
});

export const {
  SetClassStudyPlanLists,
  SetTotalClassStudyPlan,
  SetClassStudyPlanDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = ClassStudyPlanSlice.actions;
export default ClassStudyPlanSlice.reducer;
