import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const TechnologyList = lazy(() => import("../../components/Technology/TechnologyList"),);

const TechnologyListPage = () => {
  return (
        <MasterLayout>
            <Suspense fallback={<LazyLoader />}>
                <TechnologyList />
            </Suspense>
        </MasterLayout>
  );
};
export default TechnologyListPage;
