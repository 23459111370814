import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const LegalCreateUpdate = lazy(() => import("../../components/Legal/LegalCreateUpdate"));
const LegalCreateUpdatePage = () => {
  return (
    <MasterLayout>
        <Suspense fallback={<LazyLoader />}>
            <LegalCreateUpdate />
        </Suspense>
    </MasterLayout>
  );
};
export default LegalCreateUpdatePage;
