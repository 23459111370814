import {Fragment} from "react";
import FullScreenLoader from "./components/Common/FullScreenLoader";
import AppRoutes from "./components/Routes/Routes";
const App = () => {
  return (
    <Fragment>
      <AppRoutes />
      <FullScreenLoader/>
    </Fragment>
  );
};
export default App;
