import React, { Suspense, lazy } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
const LoginUser = lazy(() => import("../../components/Auth/LoginUser"));
const LoginPage = () => {
  return (
      <Suspense fallback={<LazyLoader />}>
        <LoginUser />
      </Suspense>
  );
};
export default LoginPage;
