//external import
import { createSlice } from "@reduxjs/toolkit";

const WhyusSlice = createSlice({
  name: "Whyus",
  initialState: {
    WhyusLists: [],
    TotalWhyus: 0,
    FormValue: {
      title: "",
      img: "",
      des: "",
    },
  },
  reducers: {
    SetWhyusLists(state, action) {
      state.WhyusLists = action.payload;
    },
    SetTotalWhyus(state, action) {
      state.TotalWhyus = action.payload;
    },
    SetWhyusDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      Object.keys(state.FormValue).forEach((i) => (state.FormValue[i] = ""));
    },
  },
});

export const {
  SetWhyusLists,
  SetTotalWhyus,
  SetWhyusDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = WhyusSlice.actions;
export default WhyusSlice.reducer;
