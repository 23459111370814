import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
const RecoveryResetPass = lazy(() => import("../../components/AccountRecovery/RecoveryResetPass"));
const RecoveryPasswordPage = () => {
  return (
      <Suspense fallback={<LazyLoader />}>
        <RecoveryResetPass />
      </Suspense>
  );
};
export default RecoveryPasswordPage;
