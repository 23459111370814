import { createSlice } from "@reduxjs/toolkit";
const UserSlice = createSlice({
  name: "User",
  initialState: {
    List: [],
    ListTotal: 0,
    FormValue: {
      email: "",
      firstName: "",
      lastName: "",
      mobile: "",
      password: "",
      confirmPassword: "",
    },
  },
  reducers: {
    SetUserList(state, action) {
      state.List = action.payload;
    },
    SetTotalUser(state, action) {
      state.ListTotal = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        email: "",
        firstName: "",
        lastName: "",
        mobile: "",
        password: "",
        confirmPassword: "",
      };
    },
  },
});

export const {
  SetUserList,
  SetTotalUser,
  SetFormValueOnChange,
  ResetFormValue,
} = UserSlice.actions;
export default UserSlice.reducer;
