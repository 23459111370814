import React, { lazy, Suspense } from "react";
import LazyLoader from "../../components/Common/LazyLoader";
import MasterLayout from "../../components/MasterLayout/MasterLayout";
const RecommendationCreateUpdate = lazy(() => import("../../components/Recommendation/RecommendationCreateUpdate"));

const RecommendationCreateUpdatePage = () => {
  return (
    <MasterLayout>
        <Suspense fallback={<LazyLoader />}>
            <RecommendationCreateUpdate />
        </Suspense>
    </MasterLayout>
  );
};
export default RecommendationCreateUpdatePage;
