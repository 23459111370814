//external import
import { createSlice } from "@reduxjs/toolkit";

const ClassListSlice = createSlice({
  name: "ClassList",
  initialState: {
    ClassListLists: [],
    TotalClassList: 0,
    FormValue: {
      courseID: "",
      categoryID: "",
      title: "",
      videoLink: "",
      sequence: "",
    },
  },
  reducers: {
    SetClassListLists(state, action) {
      state.ClassListLists = action.payload;
    },
    SetTotalClassList(state, action) {
      state.TotalClassList = action.payload;
    },
    SetClassListDetails(state, action) {
      state.FormValue = action.payload;
    },
    SetFormValueOnChange(state, action) {
      state.FormValue[action.payload.name] = action.payload.value;
    },
    ResetFormValue(state, action) {
      state.FormValue = {
        courseID: "",
        categoryID: "",
        title: "",
        videoLink: "",
        sequence: "",
      };
    },
  },
});

export const {
  SetClassListLists,
  SetTotalClassList,
  SetClassListDetails,
  SetFormValueOnChange,
  ResetFormValue,
} = ClassListSlice.actions;
export default ClassListSlice.reducer;
