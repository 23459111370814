import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "../../pages/AuthPage/LoginPage";
import SendOptPage from "../../pages/AccountRecoveryPage/SendOptPage";
import VerifyOptPage from "../../pages/AccountRecoveryPage/VerifyOptPage";
import RecoveryPasswordPage from "../../pages/AccountRecoveryPage/RecoveryPasswordPage";
import DashboardPage from "../../pages/DashboardPage/DashboardPage";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import UserListPage from "../../pages/UserPage/UserListPage";
import ContactListPage from "../../pages/ContactPage/ContactPage";
import FooterInfoCreateUpdatePage from "../../pages/FooterInfoPage/FooterInfoCreateUpdatePage";
import FooterInfoListPage from "../../pages/FooterInfoPage/FooterInfoListPage";
import WhyusListPage from "../../pages/WhyusPage/WhyusListPage";
import WhyusCreateUpdatePage from "../../pages/WhyusPage/WhyusCreateUpdatePage";
import LegalCreateUpdatePage from "../../pages/LegalPage/LegalCreateUpdatePage";
import LegalListPage from "../../pages/LegalPage/LegalListPage";
import RecommendationCreateUpdatePage from "../../pages/RecommendationPage/RecommendationCreateUpdatePage";
import RecommendationListPage from "../../pages/RecommendationPage/RecommendationListPage";
import HomeheroCreateUpdatePage from "../../pages/HomeheroPage/HomeheroCreateUpdatePage";
import HomeheroListPage from "../../pages/HomeheroPage/HomeheroListPage";
import CourseDetailsCreateUpdatePage from "../../pages/CourseDetailsPage/CourseDetailsCreateUpdatePage";
import CourseDetailsListPage from "../../pages/CourseDetailsPage/CourseDetailsListPage";
import CourseCreateUpdatePage from "../../pages/CoursePage/CourseCreateUpdatePage";
import CourseListPage from "../../pages/CoursePage/CourseListPage";
import ReviewCreateUpdatePage from "../../pages/ReviewPage/ReviewCreateUpdatePage";
import ReviewListPage from "../../pages/ReviewPage/ReviewListPage";
import SubscriberListPage from "../../pages/SubscriberPage/SubscriberListPage";
import TechnologyCreateUpdatePage from "../../pages/TechnologyPage/TechnologyCreateUpdatePage";
import TechnologyListPage from "../../pages/TechnologyPage/TechnologyListPage";
import AdminProfilePage from "../../pages/Admin/AdminProfilePage";
import EnrollCreateUpdatePage from "../../pages/EnrollPage/EnrollCreateUpdatePage";
import EnrollListPage from "../../pages/EnrollPage/EnrollListPage";
import ClassCategoryCreateUpdatePage from "../../pages/ClassCategoryPage/ClassCategoryCreateUpdatePage";
import ClassCategoryListPage from "../../pages/ClassCategoryPage/ClassCategoryListPage";
import ClassListCreateUpdatePage from "../../pages/ClassListPage/ClassListCreateUpdatePage";
import ClassListListPage from "../../pages/ClassListPage/ClassListListPage";
import ClassResourceCreateUpdatePage from "../../pages/ClassResourcePage/ClassResourceCreateUpdatePage";
import ClassResourceListPage from "../../pages/ClassResourcePage/ClassResourceListPage";
import ClassStudyPlanCreateUpdatePage from "../../pages/ClassStudyPlanPage/ClassStudyPlanPage";
import ClassStudyPlanListPage from "../../pages/ClassStudyPlanPage/ClassStudyPlanListPage";
import ClassAssignmentCreateUpdatePage from "../../pages/ClassAssignmentPage/ClassAssignmentCreateUpdatePage";
import ClassAssignmentList from "../ClassAssignment/ClassAssignmentList";
import ClassAssignmentListPage from "../../pages/ClassAssignmentPage/ClassAssignmentListPage";

const AppRoutes = () => {
  const { accessToken } = useSelector((state) => state.Auth);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            accessToken ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />
        <Route
          path="/signin"
          element={accessToken ? <Navigate to="/dashboard" /> : <LoginPage />}
        />
        <Route
          path="/send-otp"
          element={accessToken ? <Navigate to="/dashboard" /> : <SendOptPage />}
        />
        <Route
          path="/verify-otp"
          element={
            accessToken ? <Navigate to="/dashboard" /> : <VerifyOptPage />
          }
        />
        <Route
          path="/reset-password"
          element={
            accessToken ? (
              <Navigate to="/dashboard" />
            ) : (
              <RecoveryPasswordPage />
            )
          }
        />

        <Route
          path="/dashboard"
          element={accessToken ? <DashboardPage /> : <Navigate to="/signin" />}
        />
        <Route
          path="/profile"
          element={
            accessToken ? <AdminProfilePage /> : <Navigate to="/signin" />
          }
        />
        <Route
          path="/user-list"
          element={accessToken ? <UserListPage /> : <Navigate to="/signin" />}
        />

        <Route
          path="/contact-list"
          element={
            accessToken ? <ContactListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/footerInfo-create-update"
          element={
            accessToken ? (
              <FooterInfoCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/footerInfo-list"
          element={
            accessToken ? <FooterInfoListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/whyus-create-update"
          element={
            accessToken ? <WhyusCreateUpdatePage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/whyus-list"
          element={accessToken ? <WhyusListPage /> : <Navigate to="/signin" />}
        />

        <Route
          path="/legal-create-update"
          element={
            accessToken ? <LegalCreateUpdatePage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/legal-list"
          element={accessToken ? <LegalListPage /> : <Navigate to="/signin" />}
        />

        <Route
          path="/recommendation-create-update"
          element={
            accessToken ? (
              <RecommendationCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/recommendation-list"
          element={
            accessToken ? <RecommendationListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/homehero-create-update"
          element={
            accessToken ? (
              <HomeheroCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/homehero-list"
          element={
            accessToken ? <HomeheroListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/coursedetails-create-update"
          element={
            accessToken ? (
              <CourseDetailsCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/coursedetails-list"
          element={
            accessToken ? <CourseDetailsListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/enroll-create-update"
          element={
            accessToken ? <EnrollCreateUpdatePage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/enroll-list"
          element={accessToken ? <EnrollListPage /> : <Navigate to="/signin" />}
        />

        <Route
          path="/course-create-update"
          element={
            accessToken ? <CourseCreateUpdatePage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/course-list"
          element={accessToken ? <CourseListPage /> : <Navigate to="/signin" />}
        />

        <Route
          path="/review-list"
          element={accessToken ? <ReviewListPage /> : <Navigate to="/signin" />}
        />

        <Route
          path="/review-create-update"
          element={
            accessToken ? <ReviewCreateUpdatePage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/subscriber-list"
          element={
            accessToken ? <SubscriberListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/technology-create-update"
          element={
            accessToken ? (
              <TechnologyCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/technology-list"
          element={
            accessToken ? <TechnologyListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/class-category-create-update"
          element={
            accessToken ? (
              <ClassCategoryCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/class-category-list"
          element={
            accessToken ? <ClassCategoryListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/class-list-create-update"
          element={
            accessToken ? (
              <ClassListCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/class-list"
          element={
            accessToken ? <ClassListListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/class-resource-create-update"
          element={
            accessToken ? (
              <ClassResourceCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/class-resource-list"
          element={
            accessToken ? <ClassResourceListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/class-study-plan-create-update"
          element={
            accessToken ? (
              <ClassStudyPlanCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/class-study-plan-list"
          element={
            accessToken ? <ClassStudyPlanListPage /> : <Navigate to="/signin" />
          }
        />

        <Route
          path="/class-assignment-create-update"
          element={
            accessToken ? (
              <ClassAssignmentCreateUpdatePage />
            ) : (
              <Navigate to="/signin" />
            )
          }
        />

        <Route
          path="/class-assignment-list"
          element={
            accessToken ? <ClassAssignmentListPage />:<Navigate to="/signin"/>
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
